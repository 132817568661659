import NavBar from "../components/common/NavBar";
import { Col, Container, Image, Row, Button, Card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { orderItemState, shoppingItemState } from "../states/atoms";
import styled from "styled-components";
import { additionalBenefits, infiniteOpportunities } from "../data/pageConext";
import Footer from "../components/common/Footer";
import ContactBlock from "../components/others/ContactBlock";
import LectureDetail from "../components/program/LectureDetail";
import {
  StyledLectureDetailContainer,
  StyledRelatedProgram,
} from "../components/program/LectureDetail.styles.js";

import CheckCircle from "../icon/check-circle.svg";
import ArrowRight from "../icon/arrow-right-short.svg";
import Suitcase from "../icon/suitcase-three.svg";

export default function About() {
  const { t } = useTranslation();

  const getStartedRef = useRef(null);

  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const [lecture, setLecture] = useState([]);

  const [orderItem, setOrderItem] = useRecoilState(orderItemState);
  const [shoppingItem, setShoppingItem] = useRecoilState(shoppingItemState);
  const getDescRef = useRef(null);

  const scrollToGetStarted = useCallback(() => {
    if (getStartedRef.current) {
      getStartedRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [getStartedRef]);

  const onClickBuyNow = useCallback(() => {
    scrollToGetStarted();
  }, [lecture, navigate, setOrderItem]);

  //다운로드 url 추가전에 임시기능
  const onClickDownNow = useCallback(() => {
    scrollToLectureDetail();
  }, [lecture, navigate, setOrderItem]);

  const scrollToLectureDetail = useCallback(() => {
    if (getDescRef.current) {
      getDescRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [getDescRef]);

  const onClickEnrollNow = useCallback((plan = "monthly") => {
    if (!localStorage.getItem("accessToken")) {
      if (window.confirm("로그인 후 이용해주세요.")) {
        navigate("/auth/sign-in");
        return;
      } else {
        // 사용자가 "취소"를 눌렀을 때 아무 동작도 하지 않도록 처리
        return;
      }
    }

    setOrderItem([lecture]);
    navigate("/order", { state: { subplan: plan } });
  });

  useEffect(() => {
    AOS.init();
  });

  return (
    <>
      <div className="bg-white">
        {/* hero block */}
        <HeroBlock t={t} />
        {/* discount block */}
        <BannerBox>
          <Container
            fluid
            className="flex multi-bg-box"
            style={{
              color: "#fff",
              backgroundColor: "#171a53",
              height: "610px",
              paddingTop: "180px",
            }}
          >
            <Container className="text-center" style={{ marginBottom: "50px" }}>
              <p style={{ fontSize: "15px", textAlign: "left", color: "#6491fc" }}>
                {t("aboutPage_header_title")}
              </p>
              <div
                className="display-6 fw-bold mb-4"
                style={{ lineHeight: "1", textAlign: "left" }}
              >
                <p>{t("aboutPage_header_description1")}</p>
                <p>{t("aboutPage_header_description2")}</p>
                <MainImage>
                  <Image
                    src="/img/aboutus_1.webp"
                    style={{
                      height: "500px",
                      position: "absolute",
                      transform: "translate(-50%, -50%)",
                      objectFit: "cover",
                    }}
                  />
                </MainImage>
              </div>
              <p className="mb-1" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("aboutPage_header_description3")}
              </p>
              <p className="mb-1" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("aboutPage_header_description4")}
              </p>
              <p className="mb-4" style={{ fontSize: "15px", textAlign: "left" }}>
                {t("aboutPage_header_description5")}
              </p>
              <div style={{ textAlign: "left" }}>
                <UsButton href="/auth/sign-in">
                  Get Started
                  <span style={{ marginLeft: "8px" }}>
                    <svg
                      viewBox="0 0 32 32"
                      focusable="false"
                      aria-hidden="true"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <path
                        d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </UsButton>
                <UsButton href="https://learn.udacity.com/my-programs"
                style={{backgroundColor: "#6491FC"}}>
                  {t("aboutPage_button_connect")}
                  <span style={{ marginLeft: "8px" }}>
                    <svg
                      viewBox="0 0 32 32"
                      focusable="false"
                      aria-hidden="true"
                      style={{ width: "24px", height: "24px" }}
                    >
                      <path
                        d="M16.293 7.293a1 1 0 011.414 0l8 8a1 1 0 010 1.414l-8 8a1 1 0 01-1.414-1.414L22.585 17H7a1 1 0 01-.993-.883L6 16a1 1 0 011-1h15.585l-6.292-6.293a1 1 0 01-.083-1.32z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </span>
                </UsButton>
              </div>
            </Container>
          </Container>
        </BannerBox>
      </div>
      {/* Korean localization block */}
      <KoreanLocalizationTitleWrapper fluid>
        <Container data-aos="fade-up">
          <div className="display-6 fw-bold text-center">
            <div style={{ position: "relative", top: "50px", marginTop: "50px" }}>
              <p className="mb-4">{t("aboutPage_korean_localization_title1")}</p>
              <p
                className="mb-1"
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: "normal",
                  color: "#525252",
                }}
              >
                {t("aboutPage_korean_localization_title2")}
              </p>
              <p
                className="mb-1"
                style={{
                  fontSize: "15px",
                  textAlign: "center",
                  fontWeight: "normal",
                  color: "#525252",
                }}
              >
                {t("aboutPage_korean_localization_title3")}
              </p>
            </div>
          </div>
        </Container>
      </KoreanLocalizationTitleWrapper>
      {/*
      <Container data-aos="fade-up">
        <Row>
          <Col xs={12} md={6}>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1 }}>
                <ContentImage>
                  <Image
                    src="/img/aboutus_2.webp"
                    className="content-image"
                    style={{
                      height: "400px",
                      objectFit: "cover",
                      width: "480px",
                    }}
                  />
                </ContentImage>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                paddingLeft: "30px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  fontSize: "35px",
                  textAlign: "left",
                  color: "#2015FF",
                }}
              >
                <p
                  className="d-inline d-md-block mb-4"
                  style={{ color: "#2015FF", fontWeight: "600" }}
                >
                  한국화 프로그램
                </p>
                <div>
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    Udacity Korea Partner는 Udacity에서 가장 수요가 많고 높은 평가를 받은
                  </p>
                  <p
                    className="mb-1"
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    프로그램을 현지화하는 데 중점을 둡니다.
                  </p>
                  <p
                    className="mb-4"
                    style={{
                      fontSize: "15px",
                      textAlign: "left",
                      fontWeight: "normal",
                      color: "black",
                    }}
                  >
                    다음과 같은 프로그램을 포함합니다.
                  </p>
                  <div>
                    <p
                      className="mb-1"
                      style={{
                        fontSize: "15px",
                        textAlign: "left",
                        fontWeight: "normal",
                        color: "black",
                      }}
                    >
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      데이터 사이언스
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      자율 주행 자동차
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      소프트웨어 엔지니어링
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      생성형 AI
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      마케팅 분석
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      풀 스택 웹 개발자
                      <br />
                      <svg
                        viewBox="0 0 32 32"
                        focusable="false"
                        style={{ width: "25px", height: "25px", color: "#2015ff" }}
                      >
                        <path
                          d="M16 6c5.523 0 10 4.477 10 10s-4.477 10-10 10S6 21.523 6 16 10.477 6 16 6zm0 2a8 8 0 100 16 8 8 0 000-16zm3.143 3.486a1 1 0 011.714 1.028l-4.8 8a1 1 0 01-1.564.193l-3.2-3.2a1 1 0 011.414-1.414l2.294 2.294z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      블록체인(Blockchain)
                    </p>
                  </div>
                  <KrButton>한국화 프로그램 모두 보기</KrButton>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      */}
      <KoreanLocalizationContents data-aos="fade-up">
        <ContentBox>
          <div class="Korea-container">
            <div class="rectangle">
              <div class="Korea-title">
                {t("aboutPage_korean_localization_subtitle1_1")}
                <br />
                {t("aboutPage_korean_localization_subtitle1_2")}
              </div>
              <div className="Korea-content">
                {t("aboutPage_korean_localization_subtitle1_description")}
              </div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">
                {t("aboutPage_korean_localization_subtitle2_1")}
                <br />
                {t("aboutPage_korean_localization_subtitle2_2")}
              </div>
              <div className="Korea-content">
                {t("aboutPage_korean_localization_subtitle2_description")}
              </div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">
                {t("aboutPage_korean_localization_subtitle3_1")}
                <br /> {t("aboutPage_korean_localization_subtitle3_2")}
              </div>
              <div className="Korea-content">
                {t("aboutPage_korean_localization_subtitle3_description")}
              </div>
            </div>
            <div class="line"></div>
            <div class="rectangle">
              <div class="Korea-title">
                {t("aboutPage_korean_localization_subtitle4_1")}
                <br /> {t("aboutPage_korean_localization_subtitle4_2")}
              </div>
              <div class="Korea-content">
                {t("aboutPage_korean_localization_subtitle4_description")}
              </div>
            </div>
          </div>
        </ContentBox>
        <KrButton href="/programs/25/lectures">
          {t("aboutPage_korean_localization_button")}
        </KrButton>
      </KoreanLocalizationContents>
      {/*
      <div
        style={{
          marginTop: "100px",
          backgroundColor: "#F0F5FF",
          padding: "50px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Container className="row" data-aos="fade-up">
          <div className="col-md d-flex align-items-start mb-3 mb-md-0">
            <div>
              <Image
                src="https://uds-assets.udacity.com/glyphs/v2/checkmark-medal-two.svg"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <p style={{ color: "#2015FF", fontWeight: "700", marginBottom: "10px" }}>
                한국어 멘토 서비스
              </p>
              <p>
                현지화 혜택에는 한국인이 한국에 있는 한국인 멘토를 이용할 수 있다는 점도
                포함됩니다. (한국어를 할 수 있는 외국 엔지니어도 포함) 유다시티 나노디그리 강의마다
                2~5 개의 프로젝트가 있습니다. 그때 실제 프로젝트를 한국어로 제출하고 해당
                프로젝트에 대한 피드백을 한국어로 받을 수 있습니다.
              </p>
            </div>
          </div>
          <div className="col-md d-flex align-items-start">
            <div>
              <Image
                src="https://uds-assets.udacity.com/glyphs/v2/location-network-location-one.svg"
                style={{ width: "60px", height: "60px" }}
              />
            </div>
            <div style={{ marginLeft: "10px" }}>
              <p style={{ color: "#2015FF", fontWeight: "700", marginBottom: "10px" }}>
                유다시티 코리아 파트너 서포트 & 커뮤니티
              </p>
              <p>
                한국 내에 입점해 있는 유다시티 한국 파트너 ‘유다시티 코리아’에서도 수강생분들을
                위해 여러가지로 서포트를 해주고 있습니다. (불편사항 개선 및 기타 문의사항을 위한
                서포트)
                <br />
                한국내에 있는 유다시티 나노디그리 취득자들을 위한 커뮤니티를 운영합니다. 분기 또는
                반기마다 소통할 수 있는 정규 미팅이나 유다시티 포럼을 개최할 계획입니다.
              </p>
            </div>
          </div>
        </Container>
      </div>
      */}
      {/* subscription first block */}
      {/* 
      <Container style={{ paddingBottom: "80px" }}>
        <Row data-aos="fade-up">
          <Col xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <span className="d-block display-6 fw-bold">Udacity 전 과정을</span>
              <span className="d-block display-6 fw-bold mb-4" style={{ color: "#2015FF" }}>
                한 번에.
              </span>
              <div
                className="mb-4"
                style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal" }}
              >
                <span className="d-block">“Udacity는 비싸다.”라는 인식이 있으신가요?</span>
                <span className="d-block" style={{ width: "80%" }}>
                  그럼 혹시 <b>“단 한번의 구독을 통해 모든 과정을 한 번에 수강할 수 있다.”</b>
                  라는 것도 알고 계신가요?
                </span>
                <span className="d-block">
                  심지어 국내에서는 <b>특별 할인가</b>라는 것도 잊지마세요!
                </span>
              </div>
              <AccountButton href="/auth/sign-up">Create an Account</AccountButton>
            </div>
          </Col>
          <Col xs={12} md={6} style={{ display: "flex", justifyContent: "center" }}>
            <ContentImage>
              <Image src="/img/aboutus_3.webp" style={{ objectFit: "cover", maxWidth: "100%" }} />
            </ContentImage>
          </Col>
        </Row>
      </Container>
      */}
      {/* PriceSubscription block */}
      <PriceSubscriptionContainer style={{ paddingBottom: "80px" }}>
        <Row data-aos="fade-up">
          <Col xs={12} md={6} style={{ display: "flex", alignItems: "center" }}>
            <div>
              <span className="d-block display-6 fw-bold">
                {t("aboutPage_price_subscription_title1")}
              </span>
              <span className="d-block display-6 fw-bold mb-4" style={{ color: "#2015FF" }}>
                {t("aboutPage_price_subscription_title2")}
              </span>
              <div
                className="mb-4"
                style={{ fontSize: "15px", textAlign: "left", fontWeight: "normal", width: "80%" }}
              >
                <span className="d-block mb-4">{t("aboutPage_price_subscription_desc1")}</span>
                <span className="d-block">{t("aboutPage_price_subscription_desc2")}</span>
              </div>
              <AccountButton
                onClick={(e) => {
                  e.preventDefault();
                  onClickDownNow();
                }}
              >
                {t("aboutPage_price_subscription_btn")}
              </AccountButton>
            </div>
          </Col>
          <Col xs={12} md={6} style={{ display: "flex", justifyContent: "center" }}>
            <ContentImage>
              <Image src="/img/aboutus_3.webp" style={{ objectFit: "cover", maxWidth: "100%" }} />
            </ContentImage>
          </Col>
        </Row>
      </PriceSubscriptionContainer>
      {/* infinite opportunities block */}
      <Container
        fluid
        className="d-flex align-items-center"
        style={{ maxHeight: "1900px", paddingBottom: "80px" }}
      >
        <Container data-aos="fade-up" className="py-5">
          <Row className="d-flex justify-content-center">
            <p className="display-6 fw-bold text-center pb-4">
              {t("aboutPage_infinite_opportunities_title1")}
              <span style={{ color: "#2015FF" }}>
                {t("aboutPage_infinite_opportunities_title2")}
              </span>
              {t("aboutPage_infinite_opportunities_title3")}
            </p>
            {infiniteOpportunities.map((i) => (
              <InfiniteOpporCol xs={12} md={5}>
                <p>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-check2-circle pb-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0" />
                    <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
                  </svg>{" "}
                  {i.title}
                </p>
                <p>{t(i.description)}</p>
              </InfiniteOpporCol>
            ))}
          </Row>
        </Container>
      </Container>
      {/* certification block */}
      <Container
        fluid
        className="d-flex align-items-center bg-light py-5"
        style={{ maxHeight: "1800px" }}
      >
        <Container data-aos="fade-up" className="p-3 p-md-5">
          <Row className="d-flex align-items-center">
            <Col lg={6} className="d-none d-lg-block text-center">
              <StyledImage src="/img/certification.png" />
            </Col>
            <Col lg={6} className="d-lg-none">
              <StyledImage src="/img/certification.png" />
            </Col>
            <Col lg={6} className="pt-5">
              <p className="display-6 fw-bold mb-0">{t("aboutPage_certification_title1")}</p>
              <p className="display-6 fw-bold" style={{ color: "#2015FF" }}>
                {t("aboutPage_certification_title2")}
              </p>
              <p>{t("aboutPage_certification_description1")}</p>
              <p>{t("aboutPage_certification_description2")}</p>
              <p>{t("aboutPage_certification_description3")}</p>
              <p style={{ fontSize: "14px", color: "#898989" }}>
                {t("aboutPage_certification_extra")}
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* additional benefits block */}
      <Container
        fluid
        className="d-flex align-items-center py-5"
        style={{ backgroundColor: "#f0f5ff", maxHeight: "1900px", wordBreak: "keep-all" }}
      >
        <Container data-aos="fade-up" className="py-5">
          <Row className="d-flex align-items-center justify-content-center">
            <p className="display-6 fw-bold text-center pb-4">
              {t("aboutPage_additional_benefits_title1")}{" "}
              <span style={{ color: "blue", display: "block" }}>
                {t("aboutPage_additional_benefits_title2")}
              </span>
            </p>
            {additionalBenefits.map((i) => (
              <AdditionalBlock lg={6} xxl={3}>
                <Image src={i.icon} className="pb-3" style={{ maxHeight: "70px" }} />
                <Row className="row-cols-1">
                  <Col className="ab-title-col">
                    <p style={{ fontSize: "1.3rem", color: i.color, fontWeight: "500" }}>
                      {t(i.title)}
                    </p>
                  </Col>
                  <Col>
                    <p>{t(i.description)}</p>
                    <p className="m-0">{t(i.description2)}</p>
                    <p className="m-0">{t(i.description3)}</p>
                    <p className="m-0">{t(i.description4)}</p>
                  </Col>
                </Row>
              </AdditionalBlock>
            ))}
          </Row>
        </Container>
      </Container>
      <StyledLectureDetailContainer style={{ marginTop: 0, marginBottom: 0 }}>
        <div ref={getDescRef} className="price-container">
          <div className="title">
            <div className="sub">{t("aboutPage_additional_benefits_price")}</div>
          </div>
          <div className="contents" style={{ gap: "10px" }}>
            <div className="us-container one-monthly-sub">
              <div className="title">
                <div className="desc">
                  <div>{t("one-monthly-sub")}</div>
                  {/* <span className="discount">Save 10%</span> */}
                </div>
              </div>
              <div className="price">
                {/* 비지니스 문제로 인해 임시 주석 처리 */}
                {/* <del
                  style={{
                    color: "#696969",
                    fontWeight: "300",
                    fontSize: "2.8rem",
                    marginBottom: "10px",
                  }}
                >
                  {t("one-monthly-sub-price")}
                </del> */}
                {/* <div>{t("one-monthly-sub-discounted-price")}</div> */}
                <div>{t("one-monthly-sub-price")}</div>
              </div>
              <hr className="divider" />
              <ul className="benefits-list">
                <li style={{ fontWeight: "bold" }}>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list1")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list2")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list3")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list4")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("benefits-list5")}
                </li>
              </ul>
              <div className="button-container">
                <Button
                  onClick={() => {
                    onClickEnrollNow("monthly");
                  }}
                >
                  {t("Enroll Now")}
                  <img
                    src={ArrowRight}
                    alt="Arrow Right"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Button>
              </div>
            </div>
            <div className="us-container four-monthly-sub">
              <div className="title">
                <div className="desc">
                  <div>{t("four-monthly-sub")}</div>
                  {/* <span className="discount">Save 15%</span> */}
                </div>
                <div className="sub">{t("four-monthly-sub-time")}</div>
              </div>
              <div className="price">
                {/* 비지니스 문제로 인해 임시 주석 처리 */}
                {/*
                <del
                  style={{
                    color: "#696969",
                    fontWeight: "300",
                    fontSize: "2.8rem",
                    marginBottom: "10px",
                  }}
                >
                  {t("four-monthly-sub-original-price")}
                </del>
                */}
                {/* <div>{t("four-monthly-sub-discounted-price")}</div> */}
                <div>{t("four-monthly-sub-original-price")}</div>
              </div>
              <hr className="divider" />
              <ul className="benefits-list">
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("four-monthly-sub-benefit1")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("four-monthly-sub-benefit2")}
                </li>
                <li>
                  <img src={CheckCircle} alt="Check Circle" />
                  {t("four-monthly-sub-benefit3")}
                </li>
              </ul>
              {/* <div className="note">
                  <small>{t("four-monthly-sub-note")}</small>
                </div> */}
              <div className="button-container">
                <Button
                  onClick={() => {
                    onClickEnrollNow("fourMonth");
                  }}
                >
                  {t("Enroll Now")}
                  <img
                    src={ArrowRight}
                    alt="Arrow Right"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div className="contents-bottom">
            <div className="business-container">
              <div className="business-container-sub">
                <img
                  alt=""
                  loading="lazy"
                  width="40"
                  height="40"
                  decoding="async"
                  data-nimg="1"
                  src={Suitcase}
                  style={{ color: "transparent" }}
                />
                <div className="desc">
                  <h3>Udacity for Business</h3>
                  <p className="m-0">Buying for a business? Check out our business plans.</p>
                </div>
              </div>
              <a className="learn-more-button" href="/contact">
                Learn More
                <span>
                  <img src="/img/arrow-more.svg" alt="Arrow Right" />
                </span>
              </a>
            </div>
          </div>
        </div>
      </StyledLectureDetailContainer>
      {/* footer block */}
      <Footer />
    </>
  );
}

const KoreanLocalizationTitleWrapper = styled(Container)`
  height: 195px;
  @media (max-width: 768px) {
    height: 225px;
  }
`;

const KoreanLocalizationContents = styled(Container)`
  padding-bottom: 80px;
  @media (max-width: 768px) {
    padding-top: 30px;
  }
`;

const InfiniteOpporCol = styled(Col)`
  background-color: #f0f5ff;
  min-height: 170px;
  border-radius: 5px;
  padding: 20px;
  margin: 10px;
  word-break: keep-all;
  & p:first-child {
    padding-top: 10px;
    font-weight: 500;
    color: #2015ff;
    font-size: 1.3rem;
    margin: 0;
  }
  & p:nth-child(2) {
    padding: 10px 30px 15px 30px;
    margin: 0;
  }
`;

const AdditionalBlock = styled(Col)`
  background: linear-gradient(#191d60, #070933);
  color: #fff;
  padding: 24px;
  borderradius: 5px;
  margin: 5px;
  border-radius: 4px;
  word-break: keep-all;
  width: auto;
  min-height: 250px;
  .ab-title-col {
    height: 95px;
  }
  @media (min-width: 1200px) {
    flex: 1;
    height: 403px;
  }
  @media (min-width: 769px) and (max-width: 1199px) {
    max-width: 350px;
    height: 350px;
    .ab-title-col {
      height: 70px;
    }
  }
  @media (max-width: 768px) {
    .ab-title-col {
      height: 55px;
    }
  }
`;

export const UsButton = styled(Button)`
  background-color: #00c5a1;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  ustify-content: flex-start;
  color: black;
  align-items: center;
  &:hover {
    background-color: #047a65;
    border-color: #047a65;
    color: #ffffff;
    transition: background-color 0.3s, border-color 0.3s, color 0.3s;
  }
  margin-right: 10px;
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

const KrButton = styled(Button)`
  background-color: #2015ff;
  padding: 12px 24px;
  border: none;
  color: #fff;
  display: block;
  margin: 40px auto 0;
  width: max-content;
  &:hover {
    background-color: rgb(23, 26, 83);
    transition: all 0.1s ease 0s;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 15px;
  }
`;

const PriceSubscriptionContainer = styled(Container)`
  & {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

const AccountButton = styled(Button)`
  background-color: #2015ff;
  padding: 12px 24px;
  border: none;
  justify-content: flex-start;
  color: #fff;
  &:hover {
    background-color: rgb(23, 26, 83);
    transition: all 0.1s ease 0s;
  }

  @media (max-width: 768px) {
    width: 100%;
    font-size: 15px;
    margin-bottom: 32px;
  }
`;

const MainImage = styled.div`
  img {
    left: 75%;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (min-width: 2200px) {
    img {
      left: 67%;
    }
  }
`;

const ContentImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 768px) {
    img {
      max-height: 400px;
      width: 100%;
      flex-direction: row;
      justify-content: center;
    }
  }
`;

const HeroBlock = ({ t }) => {
  const HeroContainer = styled(Container)`
    position: relative;
    padding: 0;
    margin: 0;
  `;

  return (
    <HeroContainer>
      <NavBar />
      <Container
        fluid
        className="position-absolute start-50 translate-middle mt-4 pt-5"
        style={{ top: -48 }}
      ></Container>
      <Container className="position-absolute top-50 start-50 translate-middle"></Container>
      <LectureDetail></LectureDetail>
    </HeroContainer>
  );
};

const StyledImage = styled(Image)`
  width: 400px;
  object-fit: cover;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

const BannerBox = styled.div`
  .multi-bg-box {
    background-image: url("/img/about-back.svg");
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .container {
    @media (min-width: 1400px) {
      max-width: 1140px;
    }
  }
`;

const ContentBox = styled.div`
  border-bottom-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: #f0f5ff;

  .line {
    height: 140px;
    width: 2px;
    background-color: #fff;
  }

  .Korea-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .rectangle {
    height: 250px;
    width: calc(25% - 20px);
    padding: 30px;
  }
  .Korea-title {
    color: #2015ff;
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 15px;
  }
  @media (max-width: 1199px) {
    .Korea-container {
      flex-direction: column;
    }
    .rectangle {
      width: calc(100% - 20px);
      margin-right: 0;
      margin-bottom: 30px;
      position: relative;
      height: auto;
    }
    .line {
      height: 2px;
      width: 90%;
      background-color: #fff;
    }
    .Korea-title {
      font-size: 1.6rem;
    }
  }
`;
